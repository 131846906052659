import './BusinessGuarantee.css';
const BusinessGuarantee = ()=>{
    return(
        <section class="business w-100">
        <div class="container">
         <h2>The Best Guarantee In 
           <br/>The Business.</h2>
           <p ></p>
         {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
           <br/>ullamcorper mattis.</p>*/}
           <div class="row">
             <div class="col-lg-4 col-md-6 col-sm-12">
               <div class="business-in">
                 <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                 <div class="business-in-cont">
                   <h4>100% Satisfaction 
                     <br/> Guarantee</h4>
                 </div>
               
               </div>
             </div>
             <div class="col-lg-4 col-md-6 col-sm-12">
               <div class="business-in">
                 <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                 <div class="business-in-cont">
                   <h4>100% Satisfaction 
                     <br/> Guarantee</h4>
                 </div>
               
               </div>
             </div>
             <div class="col-lg-4 col-md-6 col-sm-12">
               <div class="business-in">
                 <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                 <div class="business-in-cont">
                   <h4>100% Satisfaction 
                     <br/> Guarantee</h4>
                 </div>
               
               </div>
             </div>

           </div>
        </div>
       </section>
    )
}
export default BusinessGuarantee;