import './Product.css';
import PageBanner from '../../component/PageBanner/PageBanner';
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const Product =()=>{

  Moment.locale('en');
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [products, setProducts] = useState({isLoading: true, list:[]});

  useEffect(() => {
    productData();
   }, []);

   const productData = () => {
    postWithOutToken(API_URL, 'products', {}).then((result) => {
        if(result.status === true){
          setProducts({isLoading : false, list: result.data.products});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 

  return(
    <div className='productpage'>
      <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
  <PageBanner/>
  <section className="best-seller w-100">
      {
              products.isLoading === false && products.list.length > 0 ?
                  <div className="container">
                    <h2>Our Products</h2>
                    <p className="best-seller-txt"></p>
                    {/*<p className="best-seller-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
                    <br/> ullamcorper.</p>*/}
                      <div className="row">

                        {
                        products.list.map((item, index) => {

                          return(
                              <div key={"pro" + index} className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="best-seller-in">
                                      <div className="best-seller-in-img best-selle-fst">
                                        <img src={item.image_path} alt=""/>
                                      </div>
                                    
                                      <h5>{item.name}</h5>
                                      <p className="price">₹ {item.price}</p>
                                      <a onClick={() => {navigate("/productdetails", {state: {id: item.id}})}}>view details</a>
                                    </div>
                            </div>
                          )
                        })
                      }
                      
                      </div>
                      {/*<button className="view-bt" type="button"><i className="fa fa-arrow-right" aria-hidden="true"></i> view more products</button>*/}
                  </div>
           : products.isLoading === true ?

           <div className="container">
             <h2>Best Seller Product</h2>
             <p className="best-seller-txt"></p>
             {/*<p className="best-seller-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
             <br/> ullamcorper.</p>*/}
               <div className="row">
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
                 <div className="col-lg-3 col-md-6 col-sm-12">
                   <div className="best-seller-in">
                   <Skeleton height={250} />
                   </div>
                 </div>
               </div>
           </div>

         : null
       }         
    </section>
      <BusinessGuarantee/>
      <Testimonial />
    </div>
  )
}
export default Product;