import PageBanner from '../../component/PageBanner/PageBanner';
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';
import prodict1 from "../../assets/imsges/detls1.jpg"
import prodict2 from "../../assets/imsges/detls2.jpg"
import './ProductDetails.css';

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const ProductDetails = () => {
  Moment.locale('en');
  const navigate = useNavigate();
  const location = useLocation();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [products_details, setProductsDetails] = useState({isLoading: true, data:{}});

  useEffect(() => {
    productData();
   }, []);

   const productData = () => {
    postWithOutToken(API_URL, 'products-details', {product_id: location.state.id}).then((result) => {
        if(result.status === true){
          setProductsDetails({isLoading : false, data: result.data.product});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 



    return(
  <div className='productDtls'>
    <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
          <PageBanner/>
          <section className="product-buying">
            <div className="container">
            <div className="row">
              {
                products_details.isLoading === false ?
                  <>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="product-buy-in">

                                  <div className="prodct-img-in">
                                    <div className="product-buy-img prdct-img-fst">
                                      <img src={products_details.data.image_path} alt=""/>
                                    </div>
                                    
                                  </div>

                            </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="product-buy-in">
                        <div className="product-buy-cont">
                            <h2>{products_details.data.name}</h2>
                            <h4>Product Code: {products_details.data.product_code}</h4>
                            <h5 className="price">₹ {products_details.data.price}</h5>
                            <div className="product-buy-p1" dangerouslySetInnerHTML={{__html:products_details.data.description}} /> 
                           </div>
                        </div>
                      </div>
                    </>
                  : products_details.isLoading === true ?

                    <>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="product-buy-in">

                                  <div className="prodct-img-in">
                                        <Skeleton height={400} width={400}/>
                                  </div>

                            </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="product-buy-in">
                        <div className="product-buy-cont">
                          <h2><Skeleton height={25} width={200}/></h2>
                              <p className="product-buy-p1"><Skeleton height={25} width={400}/></p>
                              <p className="product-buy-p2"><Skeleton height={25} width={400}/></p>
                              <p className="product-buy-p3"><Skeleton height={25} width={400}/></p>
                              <p className="product-buy-p3"><Skeleton height={25} width={400}/></p>
                            </div>
                        </div>
                      </div>
                    </>

                  : null

              }


            </div>
            </div>
          </section>
        <BusinessGuarantee/>
        <Testimonial />
      </div>
    )
}
export default ProductDetails;