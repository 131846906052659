import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState} from "react";
import { BrowserRouter, Routes, Route, useNavigate} from "react-router-dom";
import { useDispatch, useSelector} from 'react-redux';
import { setUserDataAfterLogin, localstorage_TokenAdd, setUserType } from "./Store/Reducers/CommonReducer";
// js
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";

import ScrollToTop from "./component/ScrollToTop.js";
import Layout from './pages/Layout/Layout';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Product from './pages/Product/Product';
import ProductDetails from './pages/ProductDetails/ProductDetails';
import Gallery from './pages/Gallery/Gallery';
import ContactUs from './pages/ContactUs/ContactUs';


function App() {
  const dispatch = useDispatch();
  const {userType, userData} = useSelector(state => state.common);


  return (
    <BrowserRouter >
        <ScrollToTop>
          <Routes >
              <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="about-us" element={<About />} />
                  <Route path="products" element={<Product />} />
                  <Route path="productdetails" element={<ProductDetails />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route path="contact-us" element={<ContactUs />} />
              </Route>    
          </Routes> 
        </ScrollToTop>
    </BrowserRouter> 
  );
}

export default App;
