import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  SITE_URL:"https://veda.acuitysoftware.co/veda-natural-herbal/",
  API_URL : "https://veda.acuitysoftware.co/veda-natural-herbal/api/",
  google_map_api_key: "",
  userData: null,
  userToken: "",
  pageName: null,
  userType: null,
};

export const commonReducer = createSlice({
  name: "common",
  initialState,
  reducers: {
    setUserDataWithToken: (state, action) => {
      //console.log("action data : ", action.payload);
      state.userData = action.payload.data;
      state.userToken = action.payload.token;
    },
    setUserDataWithoutToken: (state, action) => {
      //console.log(action.payload.data);
      state.userData = action.payload.data;
    },

    setUserDataAfterLogin: (state, action) => {
      //console.log(action.payload);
      state.userData = action.payload;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },

    clearUserData: (state) => {
      state.userData = null;
      state.userToken = "";
      state.userType = null;
      state.pageName = null;

    },

    localstorage_TokenAdd: (state, action) => {
      state.userToken = action.payload;
    },
    localstorage_UserdetailsAdd: (state, action) => {
      state.userData = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserDataWithToken,
  clearUserData,
  setUserDataWithoutToken,
  setUserDataAfterLogin,
  localstorage_TokenAdd,
  localstorage_UserdetailsAdd,
  setPageName,
  setUserType

} = commonReducer.actions;

export default commonReducer.reducer;
