import './Gallery.css';
import PageBanner from '../../component/PageBanner/PageBanner';
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';
import gallerytXT from "../../assets/imsges/gallery.png"

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const Gallery =()=>{

  Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [galleries, setGalleries] = useState({isLoading: true, list:[]});
    const [gallery_cms, setGalleryCms] = useState({isLoading: true, data: {}});

    useEffect(() => {
      galleryData();
     }, []);


     const galleryData = () => {
      postWithOutToken(API_URL, 'galleries', {}).then((result) => {
          if(result.status === true){
           setGalleries({isLoading : false, list: result.data.galleries});
           setGalleryCms({isLoading : false, data: result.data.cms});
          }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 

  return(
    <div className='gallerypage'>

       <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

      <PageBanner/>
      <div className='container padingcont'>
        <div className='row alrow'>

          {
            gallery_cms.isLoading === false ? 

            <>
                  <div className='col-lg-6'>
                    <div className='gal-text'>
                      <h2>{gallery_cms.data.title}</h2>
                      <div dangerouslySetInnerHTML={{__html:gallery_cms.data.description}} /> 
                    </div>
                  </div>
                  <div className='col-lg-6'>
                      <div className='gal-img'>
                      <img src={gallery_cms.data.image_path}/>
                      </div>
                  </div>
            
            </>

            : gallery_cms.isLoading === true ?

              <>
                    <div className='col-lg-6'>
                      <div className='gal-text'>
                        <h2><Skeleton height={20} width={100} /></h2>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                      </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='gal-img'>
                            <Skeleton  height={400} width={500} />
                        </div>
                    </div>
              
              </>

            : null
          }

          </div>
      
      </div>
      <section className="gallery w-100">
      {
        galleries.isLoading === false && galleries.list.length > 0 ?
          <div className="container">
            <h2>Our Gallery</h2>
            <p className="gallery-txt"></p>
            {/*<p className="gallery-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
            <br/> ullamcorper.</p>*/}
            <div className='row'>
            {
                        galleries.list.map((item, index) => {

                          return(
                           
                             <div key={'gall_' + index} className='col-md-3'>
                             <div className="gallery-in">
                                 <div className="gallery-in-Img">
                                   <img src={item.image_path} alt="" />
                                 </div>
                                 
                                 {/*<div className="overlay">
                                   <div className="plus"><i className="fa fa-plus" aria-hidden="true"></i></div>
                                 </div>*/}
                                 </div>
                             </div>
                          );

                    })
                  }
            
            </div>

          </div>
          : galleries.isLoading === true ?

          <div className="container-fuild">
            <h2>Our Gallery</h2>
            <p className="gallery-txt"></p>
            {/*<p className="gallery-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
            <br/> ullamcorper.</p>*/}
              <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
            </div>

        </div>

        : null
      }
      </section>
      <BusinessGuarantee/>
      <Testimonial />
    </div>
  )
}
export default Gallery;