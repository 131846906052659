import './Footer.css';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import logo from "../../assets/imsges/footer.png";
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import $ from 'jquery';

const Footer = ()=>{

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [siteSetting, setSiteSetting] = useState({isLoading: true, data:{}});
  const [sociallink, setSocialLink] = useState({isLoading: true, data:[]});

  useEffect(() => {
    siteSettingData();
    socialLinkData();
   }, []);

   const siteSettingData = () => {
    postWithOutToken(API_URL, 'site-settings', {}).then((result) => {
      console.log(result);
        if(result.success === true){
          setSiteSetting({isLoading : false, data: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const socialLinkData = () => {
    postWithOutToken(API_URL, 'social-links', {}).then((result) => {
      console.log(result);
        if(result.success === true){
          setSocialLink({isLoading : false, list: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const menu =[
    {
      label:"Home",
      url:"/"
    },
    {
      label:"About Us",
      url:"/about"
    },
    {
      label:"Product",
      url:"/product"
    },
    {
      label:"Gallery",
      url:"/gallery"
    },
    {
      label:"Contact Us",
      url:"/contact"
    },
      ]
   return(
      <footer>
      <div className="container">
        <div className="ft-topbar">
          <div className="ft-logo">
            {
            
                siteSetting.isLoading === false ?
                    <Link to="/"><img src={siteSetting.data.logo_path} alt="png"/></Link>
                : siteSetting.isLoading === true ?
                  <div><Skeleton height={100} width={100}/></div>
                : null
            }
          </div>
         <ul>
          {
              menu.map((item, i)=>{
                  return <li key={i}><Link to={item.url}>{item.label}</Link></li>
              })
            }
        </ul>
        <hr className="line" style={{"backgroundColor": "#D9D9D9"}}/>
        </div>
    
        <div className="ft-center">
          <div className="row">
            <div className="col-lg-12">
              <div className="ft-center-in">
                <h5>Grow Up Your Healthy Fitness With Herbs</h5>
                { siteSetting.isLoading === false ?
                    <div className='footer_text' dangerouslySetInnerHTML={{__html:siteSetting.data.footer_text}} />
                    :
                    <>
                    <div><Skeleton height={25} width={300}/></div>
                    <div><Skeleton height={25} width={300}/></div>
                    </>
                }
              </div>
            </div>
          </div>
        <div className="row">
          <div className="col-lg-5 col-md-6 col-sm-12">
            <div className="ft-center-in2">
              <h5>Contact us for best deals</h5>
              <div className="row vndrs-usrs">
               <div className="col-lg-6">
                <div className="vendrs">
                  <p>For Vendors</p>
                  {
                    siteSetting.isLoading === false ?
                      <>
                      <p><a className="add-ft" href={'mailto:' + siteSetting.data.vendor_email}>{siteSetting.isLoading === false ? siteSetting.data.vendor_email : null}</a></p> 
                      <p><a className="add-ft" href={"tel:91" + siteSetting.data.vendor_phone}>{siteSetting.isLoading === false ? siteSetting.data.vendor_phone : null}</a></p>
                      </>
                      : siteSetting.isLoading === true ?
                      <>
                      <p><Skeleton height={25} width={200}/></p>
                      <p><Skeleton height={25} width={200}/></p>
                      </>
                    : null
                  }
                
                </div>
               </div>
               <div className="col-lg-6">
                <div className="users">
                  <p>For User</p>
                  {
                    siteSetting.isLoading === false ?
                      <>
                      <p><a className="add-ft" href={'mailto:' + siteSetting.data.user_email}>{siteSetting.data.user_email}</a></p> 
                      <p><a className="add-ft" href={"tel:91" +  siteSetting.data.user_phone}>{siteSetting.data.user_phone}</a></p>
                      </>
                      : siteSetting.isLoading === true ?
                      <>
                      <p><Skeleton height={25} width={200}/></p>
                      <p><Skeleton height={25} width={200}/></p>
                      </>
                    : null
                  }                
                  </div>
               </div>
              </div>
            </div>
        </div>
    
        <div className="col-lg-3 col-md-6 col-sm-12">
          <div className="ft-center-in2 social-item">
            <h5>Follow us on:</h5>
            <ul>
            {
                sociallink.isLoading === false && sociallink.list.length > 0 ?
                    <>
                    {
                      sociallink.list.map((item, index) => {
                        return(
                          <li><a href={item.link} rel={item.name} target='_blank' >{item.name}</a></li>
                        );
                      })
                    }
                    </>
                : sociallink.isLoading === true ?
                  <>
                    <li><Skeleton height={20} /></li>
                    <li><Skeleton height={20} /></li>
                    <li><Skeleton height={20} /></li>
                    <li><Skeleton height={20} /></li>
                  </>
                : null
              }
            </ul>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="ft-center-in2">
            <h5>Contact Us</h5>
            <address>
              {
                siteSetting.isLoading === false ?
                  <>
                      <p className="add-ft">{siteSetting.data.address} {siteSetting.data.address_2} {siteSetting.data.address_3}</p>
                      <p><Link className="tel-ft" >+91 {siteSetting.data.phone} {siteSetting.data.phone_2 != null ? " \ " + siteSetting.data.phone_2 : null}</Link></p>
                      <p> <a className="mail-ft" href={'mailto:' + siteSetting.data.email}>{siteSetting.data.email}</a></p>

                  </>
                : siteSetting.isLoading === true ?

                <>
                    <p><Skeleton height={25} width={200}/></p>
                    <p><Skeleton height={25} width={200}/></p>
                    <p><Skeleton height={25} width={200}/></p>
                </>

                : null
              }
            </address>
          </div>
        </div>
        
        </div>
      </div>
      <div className="copright"><p>Copyright © 2024 Veda Harbal By Acuity Software Private Limited. All Rights Reserved.</p></div>
      </div>
     </footer>
   )
}
export default Footer;