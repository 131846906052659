import './Testimonial.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay} from 'swiper/modules';
import 'swiper/css';
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const Testimonial = () => {

  Moment.locale('en');
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [testimonials, setTestimonials] = useState({isLoading: true, list:[]});

  useEffect(() => {
    HomeData();
   }, []);

   const HomeData = () => {
    postWithOutToken(API_URL, 'testimonials', {}).then((result) => {
        if(result.status === true){
          setTestimonials({isLoading : false, list: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 


    return(
        <section className="testimonials">

          {
            testimonials.isLoading === false && testimonials.list.length > 0 ?

            <div className="container">
                <h2>Testimonials</h2>
            
                <div id="tstimnals">
                    <Swiper spaceBetween={0} slidesPerView={1}  modules={[Autoplay]}  autoplay={{ delay: 2000 }} >
                        {
                          testimonials.list.map((item, index) => {
                            return(
                              <SwiperSlide key={index}>
                                <div className="tstimnals-in">
                                  <div className="testmnials-img"> 
                                    <img src={item.image_path} alt=""/>
                                  </div>
                                  <div className="tstimnals-in-contnt">
                                  <h5>{item.client_name}</h5>
                                  <h6>{item.designation}</h6>
                                  <div dangerouslySetInnerHTML={{__html:item.description}} />
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        }
                        
              
                  </Swiper>
            
                </div>
            
              </div>

            : testimonials.isLoading === true ?
                <div className="container">
                    <h2>Testimonials</h2>
                    <div className="tstimnals-in">
                        <Skeleton height={250} />
                    </div>
                </div>    
            : null
          }
        
      </section>
    )
}
export default Testimonial;