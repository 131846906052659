import './ContactUs.css';
import PageBanner from '../../component/PageBanner/PageBanner';
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';


const ContactUs =()=>{

  Moment.locale('en');
  const navigate = useNavigate();
  const location = useLocation();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [siteSetting, setSiteSetting] = useState({isLoading: true, data:{}});
  const [products_details, setProductsDetails] = useState({isLoading: true, list:{}});

  useEffect(() => {
    siteSettingData();
   }, []);
   const siteSettingData = () => {
    postWithOutToken(API_URL, 'site-settings', {}).then((result) => {
        if(result.success === true){
          setSiteSetting({isLoading : false, data: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 

const [name, setName] = useState("");
const [address, setAddress] = useState("");
const [phone, setPhone] = useState("");
const [message, setMessage] = useState("");

const SendMes = (event) => {
  event.preventDefault();

  let flg = true;
  const all_error_msg = [];

  if(name.search(/\S/) == -1){
      all_error_msg.push("Please enter user name");
      if(flg == true){flg = false;}
  }

  if(phone.search(/\S/) == -1){
      all_error_msg.push("Please enter mobile number");
      if(flg == true){flg = false;} 
  }else{
      if(phone.length < 10){
          all_error_msg.push("Please enter 10 digit mobile number");
          if(flg == true){flg = false;}  
      }
  }
  /*if(email.search(/\S/) == -1){
    all_error_msg.push("Please enter your email");
    if(flg == true){flg = false;} 
  }else if(email.search(/\S/) != -1){
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(email) === false) {
      all_error_msg.push("Please enter valid email Id");
      if(flg == true){flg = false;}
    }
  } */

    if(address.search(/\S/) == -1){
      all_error_msg.push("Please enter your address");
      if(flg == true){flg = false;}
    }
  
  if(message.search(/\S/) == -1){
    all_error_msg.push("Please enter your message");
    if(flg == true){flg = false;}
  }

  if (flg === true) {
    
    setLoading(true);
      postWithOutToken(API_URL, 'contact-form-submit', {name: name, address: address, phone: phone, message: message}).then((result) => {
        if(result.status === true){
              setLoading(false);
              setName("");
              setAddress("");
              setPhone("");
              setMessage("");

              all_error_msg.push(result.message);
              onShowAlert("success", all_error_msg);
              
          }else{
            setLoading(false);
            all_error_msg.push(result.message);
            onShowAlert("error", all_error_msg);
          }
      })
      .catch((error) => {
        console.log("Error : ", error);
      });
    }else{
      onShowAlert("error", all_error_msg);
      
    } 


}


    return(
        <div className='contact'>
          <CustomAlert
              header={"Contact Us"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
             
     <PageBanner/>
     <section>
      <div className="contact-top-bar">
        <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="contact-box">
            <div className="contact-bx-in">
              <div className="telphn comon-icn">
                <i className="fa fa-volume-control-phone telphn-fa" aria-hidden="true"></i>
              </div>
              <div className="contact-box-cnt">
                <h3>Phone</h3>
                {
                  siteSetting.isLoading === false ?
                      <a href={"tel:91" +  siteSetting.data.phone}><span>+91 {siteSetting.data.phone}</span></a>
                  : siteSetting.isLoading === true ?
                     <p><Skeleton height={25} width={200}/></p>
                  : null
                }
                
              </div>
            </div>
          </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="contact-box">
              <div className="contact-bx-in">
            <div className="map comon-icn">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </div>
              <div className="contact-box-cnt">
              <h3>Email</h3>
             
              {
                  siteSetting.isLoading === false ?
                      <a href={'mailto:' + siteSetting.data.email}><span>{siteSetting.data.email}</span></a>
                  : siteSetting.isLoading === true ?
                  <p><Skeleton height={25} width={200}/></p>
                  : null
                }
              </div>
            </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="contact-box">
              <div className="contact-bx-in">
            <div className="comon-icn loction">
              <i className="fa fa-map-marker" aria-hidden="true"></i>
            </div>
              <div className="contact-box-cnt">
              <h3>Address</h3>
              {
                  siteSetting.isLoading === false ?
                      <p><span>{siteSetting.data.address} {siteSetting.data.address_2} {siteSetting.data.address_3}</span></p>
                  : siteSetting.isLoading === true ?
                     <p><Skeleton height={25} width={200}/></p>
                  : null
                }
              </div>
            </div>
          </div>
          </div>

        </div>
        </div>
      </div>

      <div className="from-info">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="from-info-in">
                { siteSetting.isLoading === false ?
                  <div className="map-info">
                    <iframe src={siteSetting.data.map} width="600" height="450" style={{"border":"0"}}  loading="lazy"></iframe>
                  </div>
                  : siteSetting.isLoading === true ?
                  <div className="map-info">
                      <Skeleton height={500} width={500}/>
                  </div>  
                  : null
                }
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
            {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }
              <div className="from-info-in">
                  <div className="from-info-in-right">
                        <h3>Let’s Talk</h3>
                        <p>Feel free to drop us a line below</p>
                        <div className="from-info-group">
                              <form onSubmit={SendMes}>
                                    <div className="from-info-group-in">
                                      <input className="input0-fg" type="text" placeholder="Your Name" value={name} onChange={(event) => setName(event.target.value)}/>
                                    </div>
                                    
                                    <div className="from-info-group-in">
                                      <input className="input0-fg" type="text" placeholder="Your Contact No." value={phone} onChange={(event) => setPhone(event.target.value)} maxLength={10} onKeyDown={(event) => {if (/[0-9]/.test(event.key)){ return true}else if(event.key === "Backspace"){
                                      return true;
                                  }else{event.preventDefault();}}}/>
                                    </div>

                                    <div className="from-info-group-in">
                                      <input className="input0-fg" type="text" placeholder="Your Address" value={address} onChange={(event) => setAddress(event.target.value)}/>
                                    </div>
                              
                                  <textarea className="txtarea-fg" name="" id="" placeholder="Write Here Message"  onChange={(event) => setMessage(event.target.value)} value={message}></textarea>
                                  <button type="submit" className="bt-fg" >submit</button>
                                </form>
                        </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
     <BusinessGuarantee/>
     <Testimonial />
        </div>
    )
}
export default ContactUs;