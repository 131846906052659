import PageBanner from '../../component/PageBanner/PageBanner';
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';
import './About.css';
import aboutimg from "../../assets/imsges/experience.png";
import choose from "../../assets/imsges/choose.jpg";

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';


const About = ()=>{

  Moment.locale('en');
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#017A08");

  const CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const override = CSSProperties;
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [about, setAbout] = useState({isLoading: true, data:{}});

  useEffect(() => {
    fetchData();
   }, []);

   const fetchData = () => {
    postWithOutToken(API_URL, 'about-us', {}).then((result) => {
      console.log(result);
        if(result.status === true){
          setAbout({isLoading: false, data:result.data.cms});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  //alert start
  const [alert, setAlert] = useState({
    type: null,
    text: [],
    show: false
  })

  function onCloseAlert(){
    setAlert({
      show: false,
      type: null,
      text: [],
    })
  }

  function onShowAlert(type,msg){
    setAlert({
      type: type,
      text: msg,
      show: true
    })
  }

//alert end 

   return(
        <div className='aboutpage'>
            <PageBanner />
            <section class="about">
              <div class="container">
                <div class="row">

                {
                  about.isLoading === false ?

                      <>
                            <div class="col-lg-6">
                              <div class="image">
                              <img src={about.data.image_path} alt=""/>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div class="text">
                                <h6>{about.data.title}</h6>
                                <h2>{about.data.sub_title}</h2>
                                <div dangerouslySetInnerHTML={{__html:about.data.description}} />                        
                              </div>
                            </div>
                      
                      </>

                  : about.isLoading === true ?

                      <>
                              <div class="col-lg-6">
                                <div class="image">
                                      <Skeleton  height={400} width={500} />
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div class="text">
                                  <h6><Skeleton height={20} width={100} /></h6>
                                  <h2><Skeleton height={20} width={200} /></h2>
                                    <p><Skeleton height={20} width={400} /></p>
                                    <p><Skeleton height={20} width={400} /></p>
                                    <p><Skeleton height={20} width={400} /></p>
                                    <p><Skeleton height={20} width={400} /></p>
                                    <p><Skeleton height={20} width={400} /></p>
                                    
                                </div>
                              </div>
                      </>
                  : null
              }

    </div>
  </div>
</section>

    <section class="choose">
      <div class="container">
        <div class="row">

        {
          about.isLoading === false ?

          <>
                <div class="col-lg-6">
                  <div class="image">
                  <img src={about.data.image_2_path} alt=""/>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="text">
                    <h6>{about.data.title_2}</h6>
                    <h2>{about.data.sub_title_2}</h2>
                    <div dangerouslySetInnerHTML={{__html:about.data.description_2}} />                        
                  </div>
                </div>
          
          </>

      : about.isLoading === true ?

          <>
                   <div class="col-lg-6">
                    <div class="image">
                          <Skeleton  height={400} width={500} />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="text">
                      <h6><Skeleton height={20} width={100} /></h6>
                      <h2><Skeleton height={20} width={200} /></h2>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                        <p><Skeleton height={20} width={400} /></p>
                         
                    </div>
                  </div>
          </>
      : null



    }
          
        </div>
      </div>
    </section>
      <BusinessGuarantee/>
      <Testimonial />
  </div>
   )
}
export default About;