import { Outlet } from "react-router-dom";
import './Layout.css';
import Header from "../../component/Header/Header";
import Footer from "../../component/Footer/Footer";
const Layout = ()=>{
   return(
    <div className="layout">
        <Header/>
        <Outlet/>
        <Footer/>
    </div>
   )
}
export default Layout;