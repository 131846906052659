import './Home.css';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay} from 'swiper/modules';

import 'swiper/css';
import banner from "../../assets/imsges/banner.png"
import productback from "../../assets/imsges/productback.png"
import chooseusleave from "../../assets/imsges/choose-us-leave.png"
import eyedrop from "../../assets/imsges/eyedrop.png"
import icon1 from "../../assets/imsges/icon1.png"
import icon2 from "../../assets/imsges/icon2.png"
import icon3 from "../../assets/imsges/icon3.png"
import BusinessGuarantee from '../../component/BusinessGuarantee/BusinessGuarantee';
import Testimonial from '../../component/Testimonial/Testimonial';

import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { NavLink, Link, useNavigate, useLocation} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setPageName, setUserDataAfterLogin} from "../../Store/Reducers/CommonReducer";

import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import CustomAlert from "../../component/alert/CustomAlert";
import GridLoader from "react-spinners/GridLoader";
import $ from 'jquery';
import Moment from 'moment';

const Home = ()=>{

    Moment.locale('en');
    const navigate = useNavigate();
    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#017A08");
  
    const CSSProperties = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };
  
    const override = CSSProperties;
    const dispatch = useDispatch();
    const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
    const [categories, setCategories] = useState({isLoading: true, list:[]});
    const [products, setProducts] = useState({isLoading: true, list:[]});
    const [galleries, setGalleries] = useState({isLoading: true, list:[]});
    const [slider, setSlider] = useState({isLoading: true, data: {}});
    const [work_process, setWorkingProcess] = useState({isLoading: true, list:[]});

    useEffect(() => {
      HomeData();
     }, []);

     const HomeData = () => {
      postWithOutToken(API_URL, 'home-page', {}).then((result) => {
          if(result.status === true){
            setSlider({isLoading : false, data: result.data.slider});
            setCategories({isLoading : false, list: result.data.categories});
            setProducts({isLoading : false, list: result.data.products});
            setGalleries({isLoading : false, list: result.data.galleries});
            setWorkingProcess({isLoading : false, list: result.data.work_processes});
          }
        })
        .catch((error) => {
            console.log("Error : ", error);
        });
    }

    //alert start
    const [alert, setAlert] = useState({
      type: null,
      text: [],
      show: false
    })
  
    function onCloseAlert(){
      setAlert({
        show: false,
        type: null,
        text: [],
      })
    }
  
    function onShowAlert(type,msg){
      setAlert({
        type: type,
        text: msg,
        show: true
      })
    }
  
  //alert end 

   return(
        <div className='home'>

            <CustomAlert
              header={"Document"}
              btnText={"Accept"}
              text={alert.text}
              type={alert.type}
              show={alert.show}
              onClosePress={onCloseAlert}
              pressCloseOnOutsideClick={true}
              alertStyles={{}}
              headerStyles={{}}
              textStyles={{}}
              buttonStyles={{}}
              />  
              {
                loading === true ?
                <div className="activityLoader">
                      <GridLoader
                        color={color}
                        loading={loading}
                        cssOverride={override}
                        size={30}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                        Loader="GridLoader"
                      /> 
               </div>     
               : null
              }

            <section className="hero w-100">
              <div className="container">
                <div className="row">
                { slider.isLoading === false && slider.data != null ?
                  <>
                      <div className="col-lg-6 col-md-12 col-sm">
                        <div className="hero-in hero-left">
                              <h5>{slider.data.title}</h5>
                              <h1>{slider.data.sub_title}</h1>
                              <div dangerouslySetInnerHTML={{__html:slider.data.description}} />
                              {/*<div className="click-bt">
                                <a className="read-bt" href="#">read more about</a>
                                <a className="read-bt shop-bt" href="#"><i className="fa fa-arrow-right" aria-hidden="true"></i>  shop now</a>
                              </div>*/}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm">
                        <div className="hero-in hero-right">
                        <img src={slider.data.image_path} alt=""/>
                        </div>
                      </div>
                  </>
                  : slider.isLoading === true ?

                  <>
                      <div className="col-lg-6 col-md-12 col-sm">
                        <div className="hero-in hero-left">
                              <h5><Skeleton height={20} width={100} /></h5>
                              <h1><Skeleton height={20} width={200} /></h1>
                              <p><Skeleton height={20} width={400} /></p>
                              <p><Skeleton height={20} width={400} /></p>
                              <p><Skeleton height={20} width={400} /></p>
                              <p><Skeleton height={20} width={400} /></p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm">
                        <div className="hero-in hero-right">
                            <Skeleton  height={400} width={500} />
                        </div>
                      </div>
                  </>

                  : null
                }

                </div>
              </div>
            </section>

            <div className="product-ct w-100">
              <div className="product-ct-bg">
                <img src={productback} alt="" />
              </div>
              <div className="container">
                <div className="row product-ct-rw">
      {
            categories.isLoading === false && categories.list.length > 0 ?
                  <>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div class="product-ct-in prodct-ct-contnt">
                          <h2>Product 
                            Categories</h2>
                            {/*<p>Lorem ipsum dolor sit amet, 
                              consectetur adipiscing elit. Ut 
                              elit tellus,</p>*/}
                      </div>
                    </div>
                    <div className="col-lg-9 col-md-6 col-sm-12">
                    <Swiper modules={[Autoplay]} autoplay={{ delay: 2000 }} spaceBetween={15} slidesPerView={3} >
                      {
                        categories.list.map((item, index) => {

                          return(
                                <SwiperSlide key={'cat' + index}>  
                                  <div className="product-ct-in">
                                    <div className="product-ct-in-img">
                                      <img src={item.image_path} alt=""/>
                                    </div>
                                
                                  <h5>{item.name}</h5>
                                </div>
                              </SwiperSlide>
                          );
                        })
                      }
                              
                      </Swiper>
                    
                    </div>
                </>
           : categories.isLoading === true ?
                <>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="product-ct-in">
                      <Skeleton height={200} />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="product-ct-in">
                      <Skeleton height={200} />
                      </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="product-ct-in">
                    <Skeleton height={200} />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="product-ct-in">
                    <Skeleton height={200} />
                    </div>
                  </div>
                </>

         : null

       }   
    
     
      </div>
    </div>
  </div>

        <section className="best-seller w-100">
   {
              products.isLoading === false && products.list.length > 0 ?
                <div className="container">
                  <h2>Best Seller Product</h2>
                  <p className="best-seller-txt"></p>
                  {/*<p className="best-seller-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
                  <br/> ullamcorper.</p>*/}
                    <div className="row">

                      {
                        products.list.map((item, index) => {
                          return(
                              <div key={"pro" + index} className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="best-seller-in">
                                      <div className="best-seller-in-img best-selle-fst">
                                        <img src={item.image_path} alt=""/>
                                      </div>
                                    
                                      <h5>{item.name}</h5>
                                      <p className="price">₹ {item.price}</p>
                                      <a onClick={() => {navigate("/productdetails", {state: {id: item.id}})}}>view details</a>
                                    </div>
                            </div>
                          )
                        })
                      }

                      </div>
                    <button className="view-bt" type="button" onClick={() => {navigate("/products")}}><i className="fa fa-arrow-right" aria-hidden="true"></i> view more products</button>
                </div>
              : products.isLoading === true ?

              <div className="container">
                <h2>Best Seller Product</h2>
                <p className="best-seller-txt"></p>
                {/*<p className="best-seller-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
                <br/> ullamcorper.</p>*/}
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="best-seller-in">
                      <Skeleton height={250} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="best-seller-in">
                      <Skeleton height={250} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="best-seller-in">
                      <Skeleton height={250} />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <div className="best-seller-in">
                      <Skeleton height={250} />
                      </div>
                    </div>
                  </div>
              </div>

            : null
          }  
        </section>
        <section className="gallery w-100">
      {
        galleries.isLoading === false && galleries.list.length > 0 ?

        <div className="container-fuild">
            <h2>Our Gallery</h2>
            <p className="gallery-txt"></p>
            {/*<p className="gallery-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
            <br/> ullamcorper.</p>*/}
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                <Swiper modules={[Autoplay]} autoplay={{ delay: 2000 }} spaceBetween={10} slidesPerView={4} >
                      {
                        galleries.list.map((item, index) => {

                          return(
                            <SwiperSlide key={'gall_' + index}>
                                <div className="gallery-in">
                                  <div className="gallery-in-Img">
                                    <img src={item.image_path} alt="" />
                                  </div>
                                  
                                  {/*<div className="overlay">
                                    <div className="plus"><i class="fa fa-plus" aria-hidden="true"></i></div>
                                  </div>*/}
                                </div>

                            </SwiperSlide>
                          );

                    })
                  }
                      
                  </Swiper>
                
                </div>
            </div>

          </div>


        : galleries.isLoading === true ?

          <div className="container-fuild">
            <h2>Our Gallery</h2>
            <p className="gallery-txt"></p>
            {/*<p className="gallery-txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec 
            <br/> ullamcorper.</p>*/}
              <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="best-seller-in">
                    <Skeleton height={300} />
                    </div>
                  </div>
            </div>

        </div>

        : null
      }
      
    </section>
    <section className="choose-us w-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
                <div className="choose-us-in">
                 <img src={eyedrop} alt=""/>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">

              {
                work_process.isLoading === false && work_process.list.length > 0 ?

                <div className="choose-us-in chose-us-right">
                  
                    <h2>Why Choose Us?</h2>

                    {
                      work_process.list.map((item, index) => {

                        return(
                          <div className="choose-us-box">
                            <div className="choose-us-box-icn">
                            <img src={item.image_path} alt=""/>
                            </div>
                            
                            <div className="contnts">
                              <h6>{item.title}</h6>
                              <p>{item.description}</p>
                            </div>
                          </div>
                        )
                      })
                    }
                      
                 </div>


                : work_process.isLoading === true ?

                  <div className="choose-us-in chose-us-right">
                  
                    <h2>Why Choose Us?</h2>

                      <div className="choose-us-box">
                        <div className="choose-us-box-icn">
                        <Skeleton height={40} width={40} />
                        </div>
                        
                        <div className="contnts">
                          <h6><Skeleton height={20} width={100} /></h6>
                          <p><Skeleton height={20} width={200} /></p>
                        </div>
                      </div>

                      <div className="choose-us-box">
                        <div className="choose-us-box-icn">
                        <Skeleton height={40} width={40} />
                        </div>
                        
                        <div className="contnts">
                          <h6><Skeleton height={20} width={100} /></h6>
                          <p><Skeleton height={20} width={200} /></p>
                        </div>
                      </div>

                      <div className="choose-us-box">
                        <div className="choose-us-box-icn">
                        <Skeleton height={40} width={40} />
                        </div>
                        
                        <div className="contnts">
                          <h6><Skeleton height={20} width={100} /></h6>
                          <p><Skeleton height={20} width={200} /></p>
                        </div>
                      </div>
                      
                     

                  </div>

                : null
              }

            </div>
          </div>
        </div>
        <div className="bg-leave"><img src={chooseusleave} alt=""/></div>
       </section>
       <BusinessGuarantee/>
       <Testimonial />
  </div>
   )
}
export default Home;