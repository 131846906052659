import './Header.css';
import logo from "../../assets/imsges/logo.png";
import { NavLink, Link, useNavigate} from "react-router-dom";
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";
import $ from 'jquery';
const Header = ()=>{

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { API_URL, userData, pageName, userToken} = useSelector(state => state.common);
  const [siteSetting, setSiteSetting] = useState({isLoading: true, data:{}});
  const [sociallink, setSocialLink] = useState({isLoading: true, data:[]});

  useEffect(() => {
    siteSettingData();
    socialLinkData();
   }, []);

   const siteSettingData = () => {
    postWithOutToken(API_URL, 'site-settings', {}).then((result) => {
        if(result.success === true){
          setSiteSetting({isLoading : false, data: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const socialLinkData = () => {
    postWithOutToken(API_URL, 'social-links', {}).then((result) => {
        if(result.success === true){
          setSocialLink({isLoading : false, list: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }

  const menu =[
    {
      label:"Home",
      url:"/"
    },
    {
      label:"About Us",
      url:"/about-us"
    },
    {
      label:"Products",
      url:"/products"
    },
    {
      label:"Gallery",
      url:"/gallery"
    },
    {
      label:"Contact Us",
      url:"/contact-us"
    },
      ];
      const [menuActive, setmenuActive]=useState(false);
      const menuOpen = ()=>{
        setmenuActive(!menuActive)
      }
  
   return(
<header className="w-100">
   <div className="top-bar w-100">
     <div className="container">
      <div className="row">
        <div className="col-lg-6">
             <div className="top-bar-left">
                <ul>
                  {
                    siteSetting.isLoading === false ?
                    <>
                    <li><a className="mail-ft" href={'mailto:' + siteSetting.data.email}><i className="fa fa-envelope icon" aria-hidden="true"></i>{siteSetting.data.email}</a></li>
                    <li><a className="mail-ft" href={"tel:91" + siteSetting.data.phone}><i className="fa fa-phone icon" aria-hidden="true"></i>{siteSetting.data.phone}</a></li>
                    </>
                    :
                    <>
                    <li><Skeleton height={20} width={100} /></li>
                    <li><Skeleton height={20} width={100} /></li>
                    </>
                  }
                </ul>
             </div>

        </div>
        <div className="col-lg-6">
           <div className="top-bar-right">
            <ul>
              {
                sociallink.isLoading === false && sociallink.list.length > 0 ?
                    <>
                    {
                      sociallink.list.map((item, index) => {
                        return(
                          <li><a href={item.link} rel={item.name} target='_blank' ><i className={item.icon} aria-hidden="true"></i></a></li>
                        );
                      })
                    }
                    </>
                : sociallink.isLoading === true ?
                  <>
                    <li><Skeleton height={20} width={30} /></li>
                    <li><Skeleton height={20} width={30} /></li>
                    <li><Skeleton height={20} width={30} /></li>
                    <li><Skeleton height={20} width={30} /></li>
                  </>
                : null
              }
            </ul>
             </div>
        </div>
      </div>
     </div>
   </div>
   <div id="navbar" className="w-100">
    <div className="container">
      <nav className={menuActive? "active":""}>
        <div className="logo">
          {
            
              siteSetting.isLoading === false ?
                  <Link to="/"><img src={siteSetting.data.logo_path} alt="png"/></Link>
              : siteSetting.isLoading === true ?
               <div><Skeleton height={100} /></div>
              : null
          }
          <button type='button' className='menuBtn' onClick={menuOpen}><i class="fa fa-bars" aria-hidden="true"></i></button>
        </div>
        <menu>
          <ul className="main-menu">
          {
              menu.map((item, i)=>{

                let login_btn = false;

                if(i == 4){
                  login_btn = true;
                }

                  return (
                    <>
                        <li key={i}>
                        <NavLink
                            to={item.url}
                            className={({ isActive, isPending }) =>
                            isPending ? "pending" : isActive ? "active" : ""
                            }
                        >{item.label}</NavLink>
                      </li>

                  {
                  
                      login_btn === true ? 
                      <li className="login_bt-fg" key={'login' + i}>
                         <a href="https://veda.acuitysoftware.co" target='_blank' style={{color: '#fff'}} title='Login'>Login</a>
                      </li>
                       
                      : null
                  }

                  </>

                  )
              })
            }
            </ul>
        </menu>
       </nav>
    </div>
    
   </div>
</header>
   )
}
export default Header;