import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
  } from "react-router-dom";
//import Home from './pages/Home/Home';
//import Layout from './pages/Layout/Layout';
//import About from './pages/About/About';

import { Provider } from 'react-redux'
import { store } from "./Store/AppStore";


const root = ReactDOM.createRoot(document.getElementById('root'));
/*const router = createBrowserRouter([
    {
      path:"/",
      element: <App/>,
      children:[
            {
              path:'/',
              element:<Home/>
            },
            {
              path:'about',
              element:<About/>
            }
      ]
    
    }
   
])
root.render(
  <React.StrictMode>
  <RouterProvider router={router}/>
  </React.StrictMode>
);*/

root.render(
  <Provider store={store}>
     <App />
 </Provider>
);
