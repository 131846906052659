
import './PageBanner.css';
import banrleft from "../../assets/imsges/herbill.png";
import banrright from "../../assets/imsges/herbl-tea.png"
import { Link } from 'react-router-dom';
import React, {useEffect, useState, CSSProperties, useCallback} from "react";
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import {postWithToken, postWithOutToken} from "../../Service/service";

const PageBanner = ()=>{
  let pageName
  const pageUrl = window.location.pathname
  const pagettl = pageUrl.slice(1)
  switch (pagettl) {
    case "products":
      pageName = "Our Product"
       break;
       case "gallery":
        pageName = "Gallery"
         break;
     case "productdetails":
      pageName = "Product Details"
      break;
      case "about-us":
        pageName = "About"
         break;
         case "contact-us":
          pageName = "Contact Us"
           break;
    default:
      break;
  }
  const dispatch = useDispatch();
  const { API_URL, userData, userToken} = useSelector(state => state.common);

  const [banner_data, setBannerData] = useState({isLoading: true, data: null});

  useEffect(() => {
    bannerData();
   }, []);

  const bannerData = () => {

    let slug = pagettl; 

    if(slug == "productdetails"){
      slug = "products";
    }

    postWithOutToken(API_URL, 'banner', {'slug': slug}).then((result) => {
        if(result.status === true){
          setBannerData({isLoading : false, data: result.data});
        }
      })
      .catch((error) => {
          console.log("Error : ", error);
      });
  }


   return(
<section className="banner">
  <div className="container">
    <div className="row">

    

      
        {
          banner_data.isLoading === false && banner_data.data != null ?
          <>
          
              <div className="col-lg-3">
                    <div className="inner-image-left">
                        <img src={banner_data.data.left_image_path} alt=""/>
                    </div>
              </div>
              <div className="col-lg-6">
                    <div className="inner-text">
                      <h1>{banner_data.data.title}</h1>
                      <p>{banner_data.data.description}</p>
                        <Link to="/">Home </Link ><span>/</span><Link to={pageUrl}> {pageName}</Link>
                    </div>
              </div>
              <div className="col-lg-3">
                  <div className="inner-image-right">
                    <img src={banner_data.data.right_image_path} alt=""/>
                    </div>
              </div>
    
                
          </>

          : banner_data.isLoading === true ?

            <>
          
                <div className="col-lg-3">
                      <div className="inner-image-left">
                      
                      </div>
                </div>
                <div className="col-lg-6">
                      <div className="inner-text">
                      <h1><Skeleton height={20} width={100} /></h1>
                        <p><Skeleton height={20} width={200} /></p>
                        <Link to="/">Home </Link ><span>/</span><Link to={pageUrl}> {pageName}</Link>
                 </div>
                </div>
                <div className="col-lg-3">
                    <div className="inner-image-right">
                   
                      </div>
                </div>

                  
            </>
           
          : null
        }

      
    </div>
  </div>
</section>
   )
}
export default PageBanner;